// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
window.jQuery = $;
window.$ = $;
require("@rails/ujs").start();
require("turbolinks").start();
require("@rails/activestorage").start();
require("@rails/activestorage").start();
require("channels");
require("bootstrap/dist/js/bootstrap");
require("bootstrap-datepicker/dist/js/bootstrap-datepicker");
var moment = require("moment");
window.moment = moment;
require("packs/daterangepicker");
require("packs/cocoon");
require("packs/Chart.min");
require("packs/Chart.extension");
require("packs/app");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//= require cocoon
//= require "packs/moment"
//= require "packs/daterangepicker"
