$(document).on("turbolinks:load", function() {
  console.log("hi***");

  if ($(window).width() > 1200) {
    pinSidenav();
    $(".body").addClass("g-sidenav-pinned");
  }

  var $chart = $("#chart-bars");
  // Init chart
  function initChart($chart) {
    // Create chart
    var ordersChart = new Chart($chart, {
      type: "bar",
      data: {
        labels: [
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
          "Jan",
          "Feb",
          "Mar",
          "Apr"
        ],
        datasets: [
          {
            label: "Sales",
            data: [25, 20, 30, 22, 17, 29, 22, 17, 29],
            backgroundColor: "#fb6340"
          },
          {
            label: "Purchase",
            data: [35, 30, 40, 22, 27, 35, 32, 27, 19],
            backgroundColor: "#11cdef"
            // 'primary': '#5e72e4',
            // 'info': '#11cdef',
            // 'success': '#2dce89',
            // 'danger': '#f5365c',
            // 'warning': '#fb6340'
          }
        ]
      },
      options: {
        scales: {
          xAxes: [
            {
              maxBarThickness: 10
            }
          ]
        }
      }
    });

    // Save to jQuery object
    $chart.data("chart", ordersChart);
  }

  // Init chart
  if ($chart.length) {
    initChart($chart);
  }

  $(".clickable").click(function() {
    url = $(this).data("url");
    if (url === undefined) return;
    window.location = $(this).data("url");
  });

  $(".datepicker").datepicker({
    todayHighlight: true,
    format: "dd-mm-yyyy",
    templates: {
      leftArrow: '<i class="la la-angle-left"></i>',
      rightArrow: '<i class="la la-angle-right"></i>'
    }
  });

  $("#casing_fields").on("change", ".product_select", getProdInfo);
  $("#casing_fields").on("keyup", ".qty", function() {
    calSocketQty($(this));
  });
  $("#casing_fields").on("keyup", "input", function() {
    calTotal($(this));
  });
  $("#casing_fields").on("change", "select", function() {
    calTotal($(this));
  });

  $("#tube_fields").on("keyup", "input", function() {
    calTubeTotal($(this));
  });

  $("#purchase_fields").on("keyup", "input", function() {
    calPurchaseTotal($(this));
  });

  $(".body").on("click", "[data-action]", function(e) {
    e.preventDefault();

    var $this = $(this);
    var action = $this.data("action");
    // Manage actions

    switch (action) {
      case "sidenav-pin":
        pinSidenav();
        break;

      case "sidenav-unpin":
        unpinSidenav();
        break;
    }
  });
});

function pinSidenav() {
  var body = $(".body");
  body
    .removeClass("g-sidenav-hidden")
    .addClass("g-sidenav-show g-sidenav-pinned");
  $(".sidenav-toggler").addClass("active");
  $(".sidenav-toggler").data("action", "sidenav-unpin");
  body.append(
    '<div class="backdrop d-xl-none" data-action="sidenav-unpin" data-target=' +
      $("#sidenav-main").data("target") +
      " />"
  );
}

function unpinSidenav() {
  $(".sidenav-toggler").removeClass("active");
  $(".sidenav-toggler").data("action", "sidenav-pin");
  var body = $(".body");
  body.removeClass("g-sidenav-pinned").addClass("g-sidenav-hidden");
  body.find(".backdrop").remove();
}

function getProdInfo() {
  $("#rate").val("");
  $("#s_rate").val("");
  $("#length").val("");

  $("#quantity").val("");
  $("#s_quantity").val("");

  var url = $(this).data("url");
  prod_select = $(this);
  var selected_product = prod_select.val();
  $.get(url + "/" + selected_product).done(function(data) {
    prod_select.data("length", data.length);
    fields_container = prod_select.closest(".nested-fields");
    fields_container.find(".rate").val(data.rate);
    fields_container.find(".socket_rate").val(data.socket_rate);
    fields_container.find(".length").val(data.length);
  });
}

function calSocketQty(el) {
  var quantity = el.val();
  el.closest(".nested-fields")
    .find(".socket_qty")
    .val(quantity * 2);
}

function calTotal(el) {
  var container = el.closest(".nested-fields");
  var quantity = container.find(".qty").val();
  var rate = container.find(".rate").val();
  var length = container.find(".length").val();
  var s_quantity = container.find(".socket_qty").val();
  var s_rate = container.find(".socket_rate").val();

  var prod = quantity * rate * length;
  var prod1 = s_quantity * s_rate;
  container.find("#total").val(prod + prod1);
}

function calTubeTotal(el) {
  var container = el.closest("#tube_fields");
  var weight = container.find("#payment_weight").val();
  var rate = container.find("#payment_rate").val();
  container.find("#amount").val(weight * rate);
}

function calPurchaseTotal(el) {
  var container = el.closest("#purchase_fields");
  var weight = container.find("#payment_weight").val();
  var rate = container.find("#payment_rate").val();
  var truck_fare = container
    .find("#payment_purchase_attributes_truck_fare")
    .val();
  var prod = weight * rate;
  if (truck_fare) {
    prod += parseFloat(truck_fare);
  }
  container.find("#amount").val(prod);
}
